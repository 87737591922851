.price-box-overview {
  --spacing: 20px;
  display: grid;
  grid-template-areas: "name" "." "price" "." "description";
  grid-template-rows: auto var(--spacing) auto var(--spacing) 1fr;
}

.price-box-overview--name {
  justify-self: center;
  grid-area: name;
  text-transform: uppercase;
}

.price-box-overview--price {
  grid-area: price;
  justify-self: center;
  font-size: 48px;
  font-weight: bold;
}

.price-box-overview--unit {
  font-size: 36px;
  font-weight: bolder;
}

.price-box-overview--description {
  grid-area: description;
  color: var(--ui-secondary-text-color);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsicHJpY2VfYm94X292ZXJ2aWV3LnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUVBO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTtFQUNBO0VBRUE7OztBQUVGO0VBQ0U7RUFDQTtFQUVBO0VBQ0E7OztBQUVGO0VBQ0U7RUFDQTs7O0FBRUY7RUFDRTtFQUVBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIucHJpY2UtYm94LW92ZXJ2aWV3XG4gIC0tc3BhY2luZzogMjBweFxuXG4gIGRpc3BsYXk6IGdyaWRcbiAgZ3JpZC10ZW1wbGF0ZS1hcmVhczogXCJuYW1lXCIgXCIuXCIgXCJwcmljZVwiIFwiLlwiIFwiZGVzY3JpcHRpb25cIlxuICBncmlkLXRlbXBsYXRlLXJvd3M6IGF1dG8gdmFyKC0tc3BhY2luZykgYXV0byB2YXIoLS1zcGFjaW5nKSAxZnJcblxuLnByaWNlLWJveC1vdmVydmlldy0tbmFtZVxuICBqdXN0aWZ5LXNlbGY6IGNlbnRlclxuICBncmlkLWFyZWE6IG5hbWVcblxuICB0ZXh0LXRyYW5zZm9ybTogdXBwZXJjYXNlXG5cbi5wcmljZS1ib3gtb3ZlcnZpZXctLXByaWNlXG4gIGdyaWQtYXJlYTogcHJpY2VcbiAganVzdGlmeS1zZWxmOiBjZW50ZXJcblxuICBmb250LXNpemU6IDQ4cHhcbiAgZm9udC13ZWlnaHQ6IGJvbGRcblxuLnByaWNlLWJveC1vdmVydmlldy0tdW5pdFxuICBmb250LXNpemU6IDM2cHhcbiAgZm9udC13ZWlnaHQ6IGJvbGRlclxuXG4ucHJpY2UtYm94LW92ZXJ2aWV3LS1kZXNjcmlwdGlvblxuICBncmlkLWFyZWE6IGRlc2NyaXB0aW9uXG5cbiAgY29sb3I6IHZhcigtLXVpLXNlY29uZGFyeS10ZXh0LWNvbG9yKVxuICBmb250LXNpemU6IDE4cHhcbiAgZm9udC13ZWlnaHQ6IDQwMFxuICBsaW5lLWhlaWdodDogMjRweFxuICB0ZXh0LWFsaWduOiBjZW50ZXIiXX0= */