.hint {
  font-size: 14px;
  line-height: 21px;
}
.hint i {
  margin-right: 4px;
  transform: translateY(1px);
}
@media (max-width: 430px) {
  .hint i {
    display: none;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsiaGludC5zYXNzIiwiLi4vY29uZmlnL21peGlucy9fYnJlYWtwb2ludHMuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQ09GO0VEVEE7SUFLSSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgLi4vZW52aXJvbm1lbnRcblxuLmhpbnRcbiAgZm9udC1zaXplOiAxNHB4XG4gIGxpbmUtaGVpZ2h0OiAyMXB4XG5cbiAgaVxuICAgIG1hcmdpbi1yaWdodDogNHB4XG4gICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVZKDFweClcblxuICAgICtvbi14c1xuICAgICAgZGlzcGxheTogbm9uZSIsIkBpbXBvcnQgLi4vZGVmaW5pdGlvbnMvYnJlYWtwb2ludHNcblxuPXN1cHBvcnRzLW1heC13aWR0aFxuICBtYXJnaW4taW5saW5lOiBhdXRvXG4gIG1heC13aWR0aDogMTgwMHB4XG5cbj1vbi1tb2JpbGUtYW5kLXRhYmxldFxuICBAbWVkaWEgKG1heC13aWR0aDogOTkycHgpXG4gICAgQGNvbnRlbnRcblxuPW9uLW1vYmlsZVxuICBAbWVkaWEgKG1heC13aWR0aDogNzY4cHgpXG4gICAgQGNvbnRlbnRcblxuPW9uLXhzXG4gIEBtZWRpYSAobWF4LXdpZHRoOiAkYnJlYWtwb2ludC14cylcbiAgICBAY29udGVudFxuXG49b24tdG9vLXNtYWxsLXNjcmVlbnNcbiAgQG1lZGlhIChtYXgtd2lkdGg6ICRicmVha3BvaW50LXRvby1zbWFsbClcbiAgICBAY29udGVudCJdfQ== */