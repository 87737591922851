up-drawer-box {
  padding: var(--up-drawer-padding);
  background-color: var(--mobile-navigation-background-color);
}

up-drawer-dismiss {
  --dismiss-icon-size: 2.5rem;
  left: calc(var(--container-padding) - 2px);
  right: auto;
  top: calc(var(--navigation-bar-height) - var(--dismiss-icon-size));
  font-size: var(--dismiss-icon-size);
}

up-popup.-locale-switcher {
  margin-top: 20px;
  padding: 8px;
  position: fixed !important;
  backdrop-filter: blur(1px);
  background-color: var(--brand-blue);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsidW5wb2x5LnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUVBOzs7QUFFRjtFQUNFO0VBRUE7RUFDQTtFQUNBO0VBRUE7OztBQUdBO0VBQ0U7RUFDQTtFQUNBO0VBRUE7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbInVwLWRyYXdlci1ib3hcbiAgcGFkZGluZzogdmFyKC0tdXAtZHJhd2VyLXBhZGRpbmcpXG5cbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tbW9iaWxlLW5hdmlnYXRpb24tYmFja2dyb3VuZC1jb2xvcilcblxudXAtZHJhd2VyLWRpc21pc3NcbiAgLS1kaXNtaXNzLWljb24tc2l6ZTogMi41cmVtXG5cbiAgbGVmdDogY2FsYyh2YXIoLS1jb250YWluZXItcGFkZGluZykgLSAycHgpIC8vIEFkZCAycHggb2YgdG9sZXJhbmNlIGJlY2F1c2UgdGhlIFwiWFwiIGljb24gZG9lcyBub3QgZnVsbHkgZmlsbCBpdHMgd2lkdGhcbiAgcmlnaHQ6IGF1dG9cbiAgdG9wOiBjYWxjKHZhcigtLW5hdmlnYXRpb24tYmFyLWhlaWdodCkgLSB2YXIoLS1kaXNtaXNzLWljb24tc2l6ZSkpXG5cbiAgZm9udC1zaXplOiB2YXIoLS1kaXNtaXNzLWljb24tc2l6ZSlcblxudXAtcG9wdXBcbiAgJi4tbG9jYWxlLXN3aXRjaGVyXG4gICAgbWFyZ2luLXRvcDogMjBweFxuICAgIHBhZGRpbmc6IDhweFxuICAgIHBvc2l0aW9uOiBmaXhlZCAhaW1wb3J0YW50XG5cbiAgICBiYWNrZHJvcC1maWx0ZXI6IGJsdXIoMXB4KVxuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWJyYW5kLWJsdWUpIl19 */