.mobile-navigation-company
  align-items: center
  display: flex
  gap: 8px
  margin-inline: calc(-1 * var(--up-drawer-padding))
  padding: 12px calc(var(--up-drawer-padding) + 12px) 0

  border-top: 1px solid rgb(226 232 240)
  font-size: 0.875rem
  font-weight: 400

.mobile-navigation-company--image
  display: inline-grid
  padding: 4px
  place-items: center

  img
    height: 24px
    width: 24px

.mobile-navigation-company--text
  color: var(--ui-primary-text-color)
  font-size: 0.75rem
  font-weight: 400
  transform: translateY(1px) // Because fonts are stupid