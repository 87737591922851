@import ../definitions/breakpoints

=supports-max-width
  margin-inline: auto
  max-width: 1800px

=on-mobile-and-tablet
  @media (max-width: 992px)
    @content

=on-mobile
  @media (max-width: 768px)
    @content

=on-xs
  @media (max-width: $breakpoint-xs)
    @content

=on-too-small-screens
  @media (max-width: $breakpoint-too-small)
    @content