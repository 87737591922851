.footer-social--link {
  display: block;
  height: 20px;
  width: 20px;
  color: var(--footer-icon-color);
  font-size: 20px;
}
.footer-social--link:active, .footer-social--link:hover {
  color: var(--footer-icon-hover-color);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsiZm9vdGVyX3NvY2lhbC5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBRUE7RUFDQTs7QUFFQTtFQUVFIiwic291cmNlc0NvbnRlbnQiOlsiLmZvb3Rlci1zb2NpYWwtLWxpbmtcbiAgZGlzcGxheTogYmxvY2tcbiAgaGVpZ2h0OiAyMHB4XG4gIHdpZHRoOiAyMHB4XG5cbiAgY29sb3I6IHZhcigtLWZvb3Rlci1pY29uLWNvbG9yKVxuICBmb250LXNpemU6IDIwcHhcblxuICAmOmFjdGl2ZSxcbiAgJjpob3ZlclxuICAgIGNvbG9yOiB2YXIoLS1mb290ZXItaWNvbi1ob3Zlci1jb2xvcikiXX0= */