@import ../environment

input[type="text"],
input[type="email"]
  height: 2.5rem

input[type="text"],
input[type="email"],
textarea
  width: 100%

  padding: 0.375rem 0.75rem

  background-color: rgb(249 250 251)
  border: 1px solid var(--input-border-color-dark)
  border-radius: 6px
  color: var(--ui-primary-text-color)
  font-size: 1rem
  resize: none

  +when-dark-mode
    background-color: rgb(55 65 81)
    border-color: var(--input-border-color-light)

  label + &,
  .field_with_errors &
    margin-top: 0.5rem

  &::placeholder
    color: var(--input-placeholder-color)

  &:focus-visible
    outline: 1px solid var(--ui-primary-interaction-color)

label
  display: block

.is-required label::after
  content: '*'
  color: var(--input-error-color)
  margin-left: 2px

.field_with_errors
  label
    color: var(--input-error-color)

  input,
  textarea
    border-color: var(--input-error-color) !important