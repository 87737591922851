.list--item {
  display: flex;
  align-items: center;
  column-gap: 16px;
  color: var(--ui-secondary-text-color);
}
.list--item + .list--item {
  margin-top: 16px;
}

.list--item-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: var(--ui-primary-interaction-color);
}
.list--item-icon i {
  transform: translateY(1px);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsibGlzdC5zYXNzIiwiLi4vY29uZmlnL21peGlucy9fc2l6ZXMuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7OztBQUVKO0VBQ0U7RUNYQSxPRFlZO0VDWFosUURXWTtFQUVaOztBQUVBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IC4uL2Vudmlyb25tZW50XG5cbi5saXN0LS1pdGVtXG4gIGRpc3BsYXk6IGZsZXhcbiAgYWxpZ24taXRlbXM6IGNlbnRlclxuICBjb2x1bW4tZ2FwOiAxNnB4XG4gIGNvbG9yOiB2YXIoLS11aS1zZWNvbmRhcnktdGV4dC1jb2xvcilcblxuICAmICsgJlxuICAgIG1hcmdpbi10b3A6IDE2cHhcblxuLmxpc3QtLWl0ZW0taWNvblxuICBkaXNwbGF5OiBpbmxpbmUtYmxvY2tcbiAgK2RpbWVuc2lvbnMoMTZweClcblxuICBjb2xvcjogdmFyKC0tdWktcHJpbWFyeS1pbnRlcmFjdGlvbi1jb2xvcilcblxuICBpXG4gICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVZKDFweClcbiIsIj1kaW1lbnNpb25zKCR3aWR0aCwgJGhlaWdodDogJHdpZHRoKVxuICB3aWR0aDogJHdpZHRoXG4gIGhlaWdodDogJGhlaWdodCJdfQ== */