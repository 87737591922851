@import ../environment

.hero-overlay
  width: 65%
  --spacing: 30px

  display: grid
  grid-template-areas: "logo title" ". ." "subtitle subtitle" ". ." "buttons buttons"
  grid-template-columns: auto 1fr
  grid-template-rows: auto var(--spacing) auto var(--spacing)

  +on-mobile-and-tablet
    width: 100%

.hero-overlay--logo
  align-self: center
  margin-right: 24px

  img
    height: 112px

.hero-overlay--title
  align-self: center
  grid-area: title

  color: var(--white)
  font-size: 2.25rem
  font-weight: bold
  line-height: 2.5rem
  hyphens: auto

  +on-mobile
    font-size: 2rem

.hero-overlay--subtitle
  grid-area: subtitle

  font-size: 1.25rem
  line-height: 1.5rem
  color: var(--white-80p)

  +on-mobile
    font-size: 18px
    line-height: 24px

  &.-small
    font-size: 24px
    line-height: 28px

.hero-overlay--buttons
  display: inline-block
  grid-area: buttons

.hero--button:not(:last-child)
  --button-spacing: 10px
  margin-right: var(--button-spacing)

  +on-mobile
    margin-bottom: var(--button-spacing)
