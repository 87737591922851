.badge
  border-radius: 16px
  padding: 4px 8px
  font-size: 14px
  font-weight: bolder
  width: fit-content

  &.-info
    background-color: var(--ui-primary-interaction-color)
    color: var(--white)
