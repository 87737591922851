.mobile-navigation-company {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-inline: calc(-1 * var(--up-drawer-padding));
  padding: 12px calc(var(--up-drawer-padding) + 12px) 0;
  border-top: 1px solid rgb(226, 232, 240);
  font-size: 0.875rem;
  font-weight: 400;
}

.mobile-navigation-company--image {
  display: inline-grid;
  padding: 4px;
  place-items: center;
}
.mobile-navigation-company--image img {
  height: 24px;
  width: 24px;
}

.mobile-navigation-company--text {
  color: var(--ui-primary-text-color);
  font-size: 0.75rem;
  font-weight: 400;
  transform: translateY(1px);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsibW9iaWxlX25hdmlnYXRpb25fY29tcGFueS5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUVBO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOzs7QUFFSjtFQUNFO0VBQ0E7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLm1vYmlsZS1uYXZpZ2F0aW9uLWNvbXBhbnlcbiAgYWxpZ24taXRlbXM6IGNlbnRlclxuICBkaXNwbGF5OiBmbGV4XG4gIGdhcDogOHB4XG4gIG1hcmdpbi1pbmxpbmU6IGNhbGMoLTEgKiB2YXIoLS11cC1kcmF3ZXItcGFkZGluZykpXG4gIHBhZGRpbmc6IDEycHggY2FsYyh2YXIoLS11cC1kcmF3ZXItcGFkZGluZykgKyAxMnB4KSAwXG5cbiAgYm9yZGVyLXRvcDogMXB4IHNvbGlkIHJnYigyMjYgMjMyIDI0MClcbiAgZm9udC1zaXplOiAwLjg3NXJlbVxuICBmb250LXdlaWdodDogNDAwXG5cbi5tb2JpbGUtbmF2aWdhdGlvbi1jb21wYW55LS1pbWFnZVxuICBkaXNwbGF5OiBpbmxpbmUtZ3JpZFxuICBwYWRkaW5nOiA0cHhcbiAgcGxhY2UtaXRlbXM6IGNlbnRlclxuXG4gIGltZ1xuICAgIGhlaWdodDogMjRweFxuICAgIHdpZHRoOiAyNHB4XG5cbi5tb2JpbGUtbmF2aWdhdGlvbi1jb21wYW55LS10ZXh0XG4gIGNvbG9yOiB2YXIoLS11aS1wcmltYXJ5LXRleHQtY29sb3IpXG4gIGZvbnQtc2l6ZTogMC43NXJlbVxuICBmb250LXdlaWdodDogNDAwXG4gIHRyYW5zZm9ybTogdHJhbnNsYXRlWSgxcHgpIC8vIEJlY2F1c2UgZm9udHMgYXJlIHN0dXBpZCJdfQ== */