:root {
  --container-padding: 12%;
  --navigation-bar-height: 60px;
}
@media (max-width: 768px) {
  :root {
    --container-padding: 40px;
  }
}
@media (max-width: 430px) {
  :root {
    --container-padding: 20px;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29uZmlnL3Jvb3QiLCJzb3VyY2VzIjpbIl9sYXlvdXQuc2FzcyIsIi4uL21peGlucy9fYnJlYWtwb2ludHMuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQztFQUNDO0VBQ0E7O0FDT0E7RURURDtJQUtHOzs7QUNRRjtFRGJEO0lBUUciLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IC4uL21peGlucy9icmVha3BvaW50c1xuXG5cXDpyb290XG4gIC0tY29udGFpbmVyLXBhZGRpbmc6IDEyJVxuICAtLW5hdmlnYXRpb24tYmFyLWhlaWdodDogNjBweFxuXG4gICtvbi1tb2JpbGVcbiAgICAtLWNvbnRhaW5lci1wYWRkaW5nOiA0MHB4XG5cbiAgK29uLXhzXG4gICAgLS1jb250YWluZXItcGFkZGluZzogMjBweCIsIkBpbXBvcnQgLi4vZGVmaW5pdGlvbnMvYnJlYWtwb2ludHNcblxuPXN1cHBvcnRzLW1heC13aWR0aFxuICBtYXJnaW4taW5saW5lOiBhdXRvXG4gIG1heC13aWR0aDogMTgwMHB4XG5cbj1vbi1tb2JpbGUtYW5kLXRhYmxldFxuICBAbWVkaWEgKG1heC13aWR0aDogOTkycHgpXG4gICAgQGNvbnRlbnRcblxuPW9uLW1vYmlsZVxuICBAbWVkaWEgKG1heC13aWR0aDogNzY4cHgpXG4gICAgQGNvbnRlbnRcblxuPW9uLXhzXG4gIEBtZWRpYSAobWF4LXdpZHRoOiAkYnJlYWtwb2ludC14cylcbiAgICBAY29udGVudFxuXG49b24tdG9vLXNtYWxsLXNjcmVlbnNcbiAgQG1lZGlhIChtYXgtd2lkdGg6ICRicmVha3BvaW50LXRvby1zbWFsbClcbiAgICBAY29udGVudCJdfQ== */