@import ../environment

.hero
  display: grid
  grid-template-areas: "background-start" "overlay" "background-end"
  grid-template-rows: 1fr auto 1fr
  height: 100vh

.hero--darkener
  grid-column: background-start / background-end
  grid-row: background-start / background-end
  z-index: 2

  background-color: var(--black-50p)

.hero--background
  grid-column: background-start / background-end
  grid-row: background-start / background-end
  z-index: 1

  img,
  video
    width: 100%
    height: 100%
    object-fit: cover

.hero--overlay
  +supports-max-width
  width: 100%

  grid-area: overlay
  padding-inline: var(--container-padding)
  z-index: 3

  &.-justify-right
    justify-self: right