.flashes
  position: fixed
  left: 50vw
  top: calc(#{var(--navigation-bar-height)} + 20px)
  z-index: 10

.flash
  align-items: center
  display: flex
  gap: 16px
  padding: 12px 16px
  transform: translateX(-50%)

  background-color: var(--success-color)
  color: var(--white)

.flash--remover
  cursor: pointer
  transform: translateY(1px)

  &:active,
  &:hover
    color: var(--white-hover)