@import ../environment

.sidebar-buttons
  --button-size: 60px

  position: fixed
  right: 0
  top: calc(50vh - #{var(--button-size)})
  background-color: var(--ui-primary-interaction-color)
  z-index: 3
  border-radius: 10px 0 0 10px

.sidebar-button
  +dimensions(var(--button-size))
  display: grid
  align-items: center
  justify-content: center
  text-decoration: none
  color: white

  +on-mobile
    --button-size: 40px

  &:active,
  &:hover
    color: var(--ui-primary-interaction-hover-color)

  & + &
    border-top: 1px solid white

  i
    font-size: 24px
    +dimensions(24px)

    +on-mobile
      font-size: 18px
      +dimensions(18px)

    i
      transform: translateY(1px)