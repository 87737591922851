.locale-switcher-toggle
  align-items: center
  display: flex
  gap: 8px

  text-decoration: none

.locale-switcher-toggle--title
  color: var(--white)
  font-size: 0.875rem

  .is-active &
    font-weight: bold

.locale-switcher-toggle--separator
  height: 20px
  margin-inline: 4px
  width: 1px

  background-color: var(--white)