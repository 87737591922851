@import ../environment

.tag
  background-color: #9ca3af
  color: #f9fafb
  line-height: 1.5rem
  padding: 4px
  transition: background-color var(--transition-subtle)

  +when-dark-mode
    background-color: darken(#9ca3af, 20%)

  &:hover
    cursor: default