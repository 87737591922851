.quote
  padding: 20px

  border-left: 4px solid var(--ui-primary-interaction-color)
  font-size: 20px
  font-weight: bold
  line-height: 24px

.quote--author
  font-size: 14px
  font-style: italic
  line-height: 16px