.mobile-navigation-link {
  align-items: center;
  display: grid;
  grid-template-areas: "icon text";
  grid-template-columns: auto 1fr;
  margin-left: -8px;
  padding: 12px 8px;
  border-radius: 4px;
  text-decoration: none;
}
.mobile-navigation-link:active, .mobile-navigation-link:hover, .mobile-navigation-link.-active {
  background-color: var(--mobile-navigation-item-hover-color);
  --mobile-navigation-item-icon-color: var(--mobile-navigation-item-text-hover-color);
  --mobile-navigation-item-text-color: var(--mobile-navigation-item-text-hover-color);
}
.mobile-navigation-link.-home img {
  margin-right: 8px;
  height: 32px;
  width: 32px;
}

.mobile-navigation-link--icon {
  grid-area: icon;
  height: 16px;
  margin-right: 16px;
  width: 16px;
  color: var(--mobile-navigation-item-icon-color);
  font-size: 16px;
}

.mobile-navigation-link--text {
  grid-area: text;
  color: var(--mobile-navigation-item-text-color);
  font-size: 0.875rem;
  font-weight: 400;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsibW9iaWxlX25hdmlnYXRpb25fbGluay5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBRUE7RUFDQTs7QUFFQTtFQUdFO0VBQ0E7RUFDQTs7QUFHQTtFQUNFO0VBRUE7RUFDQTs7O0FBRU47RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUVBO0VBQ0E7OztBQUVGO0VBQ0U7RUFFQTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIubW9iaWxlLW5hdmlnYXRpb24tbGlua1xuICBhbGlnbi1pdGVtczogY2VudGVyXG4gIGRpc3BsYXk6IGdyaWRcbiAgZ3JpZC10ZW1wbGF0ZS1hcmVhczogXCJpY29uIHRleHRcIlxuICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IGF1dG8gMWZyXG4gIG1hcmdpbi1sZWZ0OiAtOHB4XG4gIHBhZGRpbmc6IDEycHggOHB4XG5cbiAgYm9yZGVyLXJhZGl1czogNHB4XG4gIHRleHQtZGVjb3JhdGlvbjogbm9uZVxuXG4gICY6YWN0aXZlLFxuICAmOmhvdmVyLFxuICAmLi1hY3RpdmVcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1tb2JpbGUtbmF2aWdhdGlvbi1pdGVtLWhvdmVyLWNvbG9yKVxuICAgIC0tbW9iaWxlLW5hdmlnYXRpb24taXRlbS1pY29uLWNvbG9yOiB2YXIoLS1tb2JpbGUtbmF2aWdhdGlvbi1pdGVtLXRleHQtaG92ZXItY29sb3IpXG4gICAgLS1tb2JpbGUtbmF2aWdhdGlvbi1pdGVtLXRleHQtY29sb3I6IHZhcigtLW1vYmlsZS1uYXZpZ2F0aW9uLWl0ZW0tdGV4dC1ob3Zlci1jb2xvcilcblxuICAmLi1ob21lXG4gICAgaW1nXG4gICAgICBtYXJnaW4tcmlnaHQ6IDhweFxuXG4gICAgICBoZWlnaHQ6IDMycHhcbiAgICAgIHdpZHRoOiAzMnB4XG5cbi5tb2JpbGUtbmF2aWdhdGlvbi1saW5rLS1pY29uXG4gIGdyaWQtYXJlYTogaWNvblxuICBoZWlnaHQ6IDE2cHhcbiAgbWFyZ2luLXJpZ2h0OiAxNnB4XG4gIHdpZHRoOiAxNnB4XG5cbiAgY29sb3I6IHZhcigtLW1vYmlsZS1uYXZpZ2F0aW9uLWl0ZW0taWNvbi1jb2xvcilcbiAgZm9udC1zaXplOiAxNnB4XG5cbi5tb2JpbGUtbmF2aWdhdGlvbi1saW5rLS10ZXh0XG4gIGdyaWQtYXJlYTogdGV4dFxuXG4gIGNvbG9yOiB2YXIoLS1tb2JpbGUtbmF2aWdhdGlvbi1pdGVtLXRleHQtY29sb3IpXG4gIGZvbnQtc2l6ZTogMC44NzVyZW1cbiAgZm9udC13ZWlnaHQ6IDQwMCJdfQ== */