@import ../environment

.price-boxes
  align-items: center
  justify-content: center
  display: flex

.price-box
  display: flex
  flex-direction: column
  gap: 20px
  padding: 40px
  position: relative

  background: var(--ui-primary-background-color)
  border: 1px solid var(--border-color)
  border-radius: 24px
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25)
  hyphens: auto
  text-align: left

  +when-dark-mode
    border: 1px solid rgba(whitesmoke, 0.1)

.price-box--name
  margin-bottom: 0

  color: var(--ui-primary-interaction-color)
  font-weight: 600

.price-box--price
  grid-area: price
  justify-self: center

  font-size: 48px
  font-weight: bold

  color: var(--ui-primary-text-color)

  +on-too-small-screens
    font-size: 36px

.price-box--unit
  color: var(--ui-secondary-text-color)
  font-size: 18px
  font-weight: 600

  +on-too-small-screens
    font-size: 16px

.price-box--description
  color: var(--ui-secondary-text-color)
  line-height: 1.25rem

.price-box--button
  text-align: center
  width: 100%