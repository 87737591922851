up-drawer-box
  padding: var(--up-drawer-padding)

  background-color: var(--mobile-navigation-background-color)

up-drawer-dismiss
  --dismiss-icon-size: 2.5rem

  left: calc(var(--container-padding) - 2px) // Add 2px of tolerance because the "X" icon does not fully fill its width
  right: auto
  top: calc(var(--navigation-bar-height) - var(--dismiss-icon-size))

  font-size: var(--dismiss-icon-size)

up-popup
  &.-locale-switcher
    margin-top: 20px
    padding: 8px
    position: fixed !important

    backdrop-filter: blur(1px)
    background-color: var(--brand-blue)