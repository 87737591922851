.footer-social--link
  display: block
  height: 20px
  width: 20px

  color: var(--footer-icon-color)
  font-size: 20px

  &:active,
  &:hover
    color: var(--footer-icon-hover-color)