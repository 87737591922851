.background-medium
  position: relative
  max-height: calc(100vh + 20px)

  &.-narrow
    height: calc(60vh)

  video,
  img
    z-index: 1
    width: 100%
    height: 100%
    object-fit: cover

  &--overlay
    position: absolute
    top: 0
    left: 0
    z-index: 2
    width: 100%
    height: 100%
    backdrop-filter: blur(6px)
    background-color: rgba(#000, 0.4)

    &.-darker
      background-color: rgba(#000, 0.6)

  &--content
    position: absolute
    top: 0
    left: 0
    z-index: 3
    width: 100%
    height: 100%
    color: white

  &--gradient
    position: absolute
    width: 100%
    height: 20px
    background-color: rgba(255, 255, 255, 0.4)

    &.-top
      top: 0

    &.-bottom
      bottom: 0

  &--separator
    height: 4px
    background-color: black