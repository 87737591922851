.number {
  display: inline;
  font-weight: bold;
}

.number--unit {
  display: inline;
  font-size: 40px;
  margin-left: -10px;
  font-weight: bold;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsibnVtYmVyLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLm51bWJlclxuICBkaXNwbGF5OiBpbmxpbmVcbiAgZm9udC13ZWlnaHQ6IGJvbGRcblxuLm51bWJlci0tdW5pdFxuICBkaXNwbGF5OiBpbmxpbmVcbiAgZm9udC1zaXplOiA0MHB4XG4gIG1hcmdpbi1sZWZ0OiAtMTBweFxuICBmb250LXdlaWdodDogYm9sZFxuIl19 */