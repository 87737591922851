@charset "UTF-8";
.dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdown::after {
  content: "▼";
  margin-left: 8px;
  transform: translateY(2px);
  color: var(--white);
  font-size: 12px;
}
.dropdown:active::after, .dropdown:hover::after {
  color: var(--white-hover);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsiZHJvcGRvd24uc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQUE7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7RUFFQTtFQUNBOztBQUlBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuZHJvcGRvd25cbiAgZGlzcGxheTogZmxleFxuICBhbGlnbi1pdGVtczogY2VudGVyXG4gIGN1cnNvcjogcG9pbnRlclxuXG4gICY6OmFmdGVyXG4gICAgY29udGVudDogJ+KWvCdcbiAgICBtYXJnaW4tbGVmdDogOHB4XG4gICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVZKDJweClcblxuICAgIGNvbG9yOiB2YXIoLS13aGl0ZSlcbiAgICBmb250LXNpemU6IDEycHhcblxuICAmOmFjdGl2ZSxcbiAgJjpob3ZlclxuICAgICY6OmFmdGVyXG4gICAgICBjb2xvcjogdmFyKC0td2hpdGUtaG92ZXIpIl19 */