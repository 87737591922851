.license-badge {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  background-color: var(--ui-primary-interaction-color);
  color: var(--white);
  padding: 2px;
  transform: translateY(-2px);
  transition: background-color var(--transition-subtle);
}
.license-badge:hover {
  background-color: var(--ui-primary-interaction-hover-color);
  cursor: default;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsibGljZW5zZV9iYWRnZS5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIubGljZW5zZS1iYWRnZVxuICBkaXNwbGF5OiBpbmxpbmUtYmxvY2tcbiAgZm9udC1zaXplOiAxNHB4XG4gIGxpbmUtaGVpZ2h0OiAxNnB4XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXVpLXByaW1hcnktaW50ZXJhY3Rpb24tY29sb3IpXG4gIGNvbG9yOiB2YXIoLS13aGl0ZSlcbiAgcGFkZGluZzogMnB4XG4gIHRyYW5zZm9ybTogdHJhbnNsYXRlWSgtMnB4KVxuICB0cmFuc2l0aW9uOiBiYWNrZ3JvdW5kLWNvbG9yIHZhcigtLXRyYW5zaXRpb24tc3VidGxlKVxuXG4gICY6aG92ZXJcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS11aS1wcmltYXJ5LWludGVyYWN0aW9uLWhvdmVyLWNvbG9yKVxuICAgIGN1cnNvcjogZGVmYXVsdCJdfQ== */