.quote {
  padding: 20px;
  border-left: 4px solid var(--ui-primary-interaction-color);
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

.quote--author {
  font-size: 14px;
  font-style: italic;
  line-height: 16px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsicXVvdGUuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBRUE7RUFDQTtFQUNBO0VBQ0E7OztBQUVGO0VBQ0U7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLnF1b3RlXG4gIHBhZGRpbmc6IDIwcHhcblxuICBib3JkZXItbGVmdDogNHB4IHNvbGlkIHZhcigtLXVpLXByaW1hcnktaW50ZXJhY3Rpb24tY29sb3IpXG4gIGZvbnQtc2l6ZTogMjBweFxuICBmb250LXdlaWdodDogYm9sZFxuICBsaW5lLWhlaWdodDogMjRweFxuXG4ucXVvdGUtLWF1dGhvclxuICBmb250LXNpemU6IDE0cHhcbiAgZm9udC1zdHlsZTogaXRhbGljXG4gIGxpbmUtaGVpZ2h0OiAxNnB4Il19 */