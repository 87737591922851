.license-badge
  display: inline-block
  font-size: 14px
  line-height: 16px
  background-color: var(--ui-primary-interaction-color)
  color: var(--white)
  padding: 2px
  transform: translateY(-2px)
  transition: background-color var(--transition-subtle)

  &:hover
    background-color: var(--ui-primary-interaction-hover-color)
    cursor: default