@import ../environment

.footer
  background: var(--ui-primary-background-color)
  color: var(--ui-secondary-text-color)
  font-size: 14px
  box-shadow: 0 -4px var(--ui-secondary-text-color)

.footer--content
  display: grid
  align-items: center
  gap: 32px
  grid-template-areas: "links copyright social"
  grid-template-columns: repeat(3, 1fr)

  padding: 20px var(--container-padding)

  +supports-max-width

  +on-mobile
    gap: 8px
    grid-template-areas: "links social" "copyright social"
    grid-template-columns: 1fr auto

  +on-xs
    gap: 16px
    grid-template-areas: "social" "links" "copyright"
    grid-template-columns: 1fr

.footer--copyright
  grid-area: copyright
  justify-self: center

  font-weight: 400
  text-align: center

  +on-mobile
    justify-self: left

  +on-xs
    justify-self: center

.footer--links
  display: flex
  gap: 20px
  grid-area: links
  justify-self: left

  +on-xs
    justify-self: center

.footer--social
  display: flex
  gap: 20px
  grid-area: social
  justify-self: right

  +on-xs
    justify-self: center
    margin-bottom: 8px