@import ../environment

.segment-title
  font-size: 2rem
  font-weight: bold
  hyphens: auto
  line-height: 2.5rem

  &.-small
    font-size: 48px

.segment-title-decorators
  margin-bottom: var(--segment-title-margin-bottom)
  margin-top: calc(-1 * var(--segment-title-margin-bottom))

.segment-title-decorator
  display: inline-block
  height: 4px
  width: 50px

  & + &
    margin-left: -2px

  &:first-child
    background-color: var(--ui-primary-interaction-color)
  &:last-child
    background-color: var(--ui-primary-interaction-hover-color)