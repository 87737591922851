@import ../environment

.tai--image
  img
    box-shadow: 0 35px 80px -12px rgb(0 0 0 / 0.9)

    +on-mobile
      box-shadow: 0 10px 30px -12px rgb(0 0 0 / 0.9)

  &.-shrink-on-mobile
    +on-mobile-and-tablet
      margin-inline: auto
      width: 60%

    +on-xs
      width: 80%

  &.-without-shadow
    img
      box-shadow: none

  +on-too-small-screens
    display: none