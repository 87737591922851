@import ../definitions/colors

\:root
  --white: rgb(255, 255, 255)
  --white-hover: rgb(209, 213, 219)
  --white-60p: rgba(255, 255, 255, 0.6)
  --white-80p: rgba(255, 255, 255, 0.8)
  --black: rgb(0, 0, 0)
  --black-70p: rgba(0, 0, 0, 0.7)
  --black-50p: rgba(0, 0, 0, 0.5)
  --black-20p: rgba(0, 0, 0, 0.2)
  --teal: #27cfcd
  --brand-blue: rgb(5, 0, 67)
  --brand-blue-60p: rgba(5, 0, 67, 0.6)
  --blue: #1c5394
  --dark-blue: #060046
  --lighter-black: #{$lighter-black}

  --link-color: #3b82f6
  --link-hover-color: #2563eb

  --ui-primary-interaction-color: #2563eb
  --ui-primary-interaction-hover-color: #1d4ed8
  --ui-primary-text-color: rgb(0, 0, 0)
  --ui-secondary-text-color: rgb(75 85 99)
  --ui-primary-background-color: var(--white)
  --ui-secondary-background-color: rgb(241 245 249)
  --ui-dark-background-1: #12151E
  --ui-dark-background-2: #222839
  --ui-dark-background-gradient: linear-gradient(to bottom right, var(--ui-dark-background-1), var(--ui-dark-background-2))

  --footer-text-color: rgb(98, 101, 109)
  --footer-text-hover-color: var(--black)
  --footer-icon-color: rgb(156, 163, 175)
  --footer-icon-hover-color: rgb(107, 114, 128)

  --border-color: #e5e7eb
  --input-border-color-light: rgb(75 85 99)
  --input-border-color-dark: rgb(209 213 219)
  --input-error-color: rgb(220 38 38)
  --input-placeholder-color: gray
  --success-color: #059669

  --mobile-navigation-background-color: var(--white)
  --mobile-navigation-item-icon-color: #A0A6B1
  --mobile-navigation-item-text-color: #5C636F
  --mobile-navigation-item-text-hover-color: var(--black)
  --mobile-navigation-item-hover-color: #F4F5F7