.flashes {
  position: fixed;
  left: 50vw;
  top: calc(var(--navigation-bar-height) + 20px);
  z-index: 10;
}

.flash {
  align-items: center;
  display: flex;
  gap: 16px;
  padding: 12px 16px;
  transform: translateX(-50%);
  background-color: var(--success-color);
  color: var(--white);
}

.flash--remover {
  cursor: pointer;
  transform: translateY(1px);
}
.flash--remover:active, .flash--remover:hover {
  color: var(--white-hover);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsiZmxhc2guc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFFQTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0E7O0FBRUE7RUFFRSIsInNvdXJjZXNDb250ZW50IjpbIi5mbGFzaGVzXG4gIHBvc2l0aW9uOiBmaXhlZFxuICBsZWZ0OiA1MHZ3XG4gIHRvcDogY2FsYygje3ZhcigtLW5hdmlnYXRpb24tYmFyLWhlaWdodCl9ICsgMjBweClcbiAgei1pbmRleDogMTBcblxuLmZsYXNoXG4gIGFsaWduLWl0ZW1zOiBjZW50ZXJcbiAgZGlzcGxheTogZmxleFxuICBnYXA6IDE2cHhcbiAgcGFkZGluZzogMTJweCAxNnB4XG4gIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgtNTAlKVxuXG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXN1Y2Nlc3MtY29sb3IpXG4gIGNvbG9yOiB2YXIoLS13aGl0ZSlcblxuLmZsYXNoLS1yZW1vdmVyXG4gIGN1cnNvcjogcG9pbnRlclxuICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVkoMXB4KVxuXG4gICY6YWN0aXZlLFxuICAmOmhvdmVyXG4gICAgY29sb3I6IHZhcigtLXdoaXRlLWhvdmVyKSJdfQ== */