@import ../environment

.splide__progress
  bottom: 0
  position: absolute
  left: 0
  right: 0
  z-index: 1

.splide__progress__bar
  height: 4px

  background: var(--ui-primary-interaction-color)

[data-section="light"],
[data-section="gray"]
  .splide__pagination__page
    background-color: var(--ui-secondary-text-color)

    &.is-active
      background-color: var(--ui-primary-text-color)

.splide__arrow
  +on-mobile
    display: none