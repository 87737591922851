@import ../environment

.logo
  +dimensions(128px)
  user-select: none

.logo--link
  display: flex
  flex-wrap: wrap
  column-gap: 40px
  row-gap: 20px
  text-decoration: none

.logo--copyright
  font-size: 16px
  color: rgb(203 213 225)
  margin-top: 80px
  text-align: center