.badge {
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: bolder;
  width: fit-content;
}
.badge.-info {
  background-color: var(--ui-primary-interaction-color);
  color: var(--white);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsiYmFkZ2Uuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLmJhZGdlXG4gIGJvcmRlci1yYWRpdXM6IDE2cHhcbiAgcGFkZGluZzogNHB4IDhweFxuICBmb250LXNpemU6IDE0cHhcbiAgZm9udC13ZWlnaHQ6IGJvbGRlclxuICB3aWR0aDogZml0LWNvbnRlbnRcblxuICAmLi1pbmZvXG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tdWktcHJpbWFyeS1pbnRlcmFjdGlvbi1jb2xvcilcbiAgICBjb2xvcjogdmFyKC0td2hpdGUpXG4iXX0= */