@import ../environment

.descriptions
  --bs-gutter-x: 3rem

.description
  display: grid
  grid-template-areas: "icon . title" ". . content"
  grid-template-columns: auto 24px 1fr

  +on-xs
    grid-template-areas: "icon . title" ". . . " "content-start . content-end"
    grid-template-columns: auto 16px 1fr
    grid-template-rows: auto 16px auto

.description--icon
  display: grid
  grid-area: icon
  height: 2.5rem
  place-items: center
  width: 2.5rem

  background-color: var(--ui-primary-interaction-color)
  border-radius: 8px
  color: var(--white)
  font-size: 20px

.description--title
  grid-area: title

  font-weight: bold
  hyphens: auto
  line-height: 1.5rem
  overflow: hidden
  text-overflow: ellipsis

  +on-xs
    align-self: center

.description--text
  grid-area: content

  color: var(--ui-secondary-text-color)
  font-weight: 400
  line-height: 1.5rem
  hyphens: auto

  +on-xs
    grid-column: content-start / content-end
    grid-row: content-start / content-end

.description--learn-more
  display: inline-block
  color: var(--ui-primary-interaction-color)
  text-decoration: none
  font-size: 20px
  margin-top: 20px
  padding-bottom: 4px
  border-bottom: 1px solid transparent
  transition: var(--transition-subtle)

  &:hover
    border-bottom: 1px solid
    color: var(--ui-primary-interaction-hover-color)

  i
    font-size: 18px
    transform: translateY(2px)
