.tag {
  background-color: #9ca3af;
  color: #f9fafb;
  line-height: 1.5rem;
  padding: 4px;
  transition: background-color var(--transition-subtle);
}
body[is-dark-mode] .tag {
  background-color: #666f7f;
}
.tag:hover {
  cursor: default;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsidGFnLnNhc3MiLCIuLi9jb25maWcvbWl4aW5zL190aGVtZS5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUNGQTtFREtFOztBQUVGO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IC4uL2Vudmlyb25tZW50XG5cbi50YWdcbiAgYmFja2dyb3VuZC1jb2xvcjogIzljYTNhZlxuICBjb2xvcjogI2Y5ZmFmYlxuICBsaW5lLWhlaWdodDogMS41cmVtXG4gIHBhZGRpbmc6IDRweFxuICB0cmFuc2l0aW9uOiBiYWNrZ3JvdW5kLWNvbG9yIHZhcigtLXRyYW5zaXRpb24tc3VidGxlKVxuXG4gICt3aGVuLWRhcmstbW9kZVxuICAgIGJhY2tncm91bmQtY29sb3I6IGRhcmtlbigjOWNhM2FmLCAyMCUpXG5cbiAgJjpob3ZlclxuICAgIGN1cnNvcjogZGVmYXVsdCIsIj13aGVuLWxpZ2h0LW1vZGVcbiAgYm9keTpub3QoW2lzLWRhcmstbW9kZV0pICZcbiAgICBAY29udGVudFxuXG49d2hlbi1kYXJrLW1vZGVcbiAgYm9keVtpcy1kYXJrLW1vZGVdICZcbiAgICBAY29udGVudCJdfQ== */