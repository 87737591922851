.header-theme-switcher--button {
  color: var(--header-text-color);
}
.header-theme-switcher--button:active, .header-theme-switcher--button:hover {
  color: var(--header-text-hover-color);
}
.header-theme-switcher--button.for-light {
  display: none;
}
.header-theme-switcher--button.for-dark {
  display: initial;
}
body[is-dark-mode] .header-theme-switcher--button.for-light {
  display: initial;
}
body[is-dark-mode] .header-theme-switcher--button.for-dark {
  display: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsiaGVhZGVyX3RoZW1lX3N3aXRjaGVyLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTs7QUFFQTtFQUVFOztBQUVGO0VBQ0U7O0FBRUY7RUFDRTs7QUFHQTtFQUNFOztBQUVGO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IC4uL2Vudmlyb25tZW50XG5cbi5oZWFkZXItdGhlbWUtc3dpdGNoZXItLWJ1dHRvblxuICBjb2xvcjogdmFyKC0taGVhZGVyLXRleHQtY29sb3IpXG5cbiAgJjphY3RpdmUsXG4gICY6aG92ZXJcbiAgICBjb2xvcjogdmFyKC0taGVhZGVyLXRleHQtaG92ZXItY29sb3IpXG5cbiAgJi5mb3ItbGlnaHRcbiAgICBkaXNwbGF5OiBub25lXG5cbiAgJi5mb3ItZGFya1xuICAgIGRpc3BsYXk6IGluaXRpYWxcblxuICArd2hlbi1kYXJrLW1vZGVcbiAgICAmLmZvci1saWdodFxuICAgICAgZGlzcGxheTogaW5pdGlhbFxuXG4gICAgJi5mb3ItZGFya1xuICAgICAgZGlzcGxheTogbm9uZSJdfQ== */