.locale-switcher--button {
  display: none;
  cursor: pointer;
}
.locale-switcher.-initialized .locale-switcher--button {
  display: block;
}

.locale-switcher.-initialized .locale-switcher--popup {
  display: none;
}

.locale-switcher--toggles {
  display: flex;
  gap: 8px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsibG9jYWxlX3N3aXRjaGVyLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSUE7RUFDRTtFQUVBOztBQU5BO0VBU0U7OztBQVRGO0VBYUU7OztBQUVKO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIj13aGVuLWxvY2FsZS1zd2l0Y2hlci1pbml0aWFsaXplZFxuICAubG9jYWxlLXN3aXRjaGVyLi1pbml0aWFsaXplZCAmXG4gICAgQGNvbnRlbnRcblxuLmxvY2FsZS1zd2l0Y2hlci0tYnV0dG9uXG4gIGRpc3BsYXk6IG5vbmVcblxuICBjdXJzb3I6IHBvaW50ZXJcblxuICArd2hlbi1sb2NhbGUtc3dpdGNoZXItaW5pdGlhbGl6ZWRcbiAgICBkaXNwbGF5OiBibG9ja1xuXG4ubG9jYWxlLXN3aXRjaGVyLS1wb3B1cFxuICArd2hlbi1sb2NhbGUtc3dpdGNoZXItaW5pdGlhbGl6ZWRcbiAgICBkaXNwbGF5OiBub25lXG5cbi5sb2NhbGUtc3dpdGNoZXItLXRvZ2dsZXNcbiAgZGlzcGxheTogZmxleFxuICBnYXA6IDhweCJdfQ== */