@import ../environment

.hint
  font-size: 14px
  line-height: 21px

  i
    margin-right: 4px
    transform: translateY(1px)

    +on-xs
      display: none