@import ../environment

.segment
  --section-background: var(--ui-primary-background-color)
  --vertical-padding: 120px

  padding-block: var(--vertical-padding)
  position: relative

  background: var(--section-background)
  color: var(--ui-primary-text-color)
  transition: background var(--transition-subtle)

  &.-gray
    --section-background: var(--ui-secondary-background-color)

  +on-mobile
    --vertical-padding: 60px

    &:first-child
      --vertical-padding: calc(60px + var(--navigation-bar-height))

.segment--gradient
  height: 80%
  position: absolute
  width: 100%
  top: 10%
  left: 0
  z-index: 0

  background: linear-gradient(90deg, rgba(39,207,205,0.6) 0%, rgba(255,255,255,1) 100%)
  filter: blur(64px)
  opacity: 0.4

.segment--content
  +supports-max-width

  padding-inline: var(--container-padding)
  position: relative
  z-index: 3

  &.-within-splide
    padding-bottom: var(--vertical-padding)

  &.-centered
    text-align: center

.segment--title
  margin-bottom: var(--segment-title-margin-bottom)

  &.-with-small-margin
    margin-bottom: 40px

.segment--subtitle
  font-size: 1rem
  font-weight: bold
  line-height: 1.5rem
  margin-bottom: 20px

.segment--text
  color: var(--ui-secondary-text-color)
  hyphens: auto
  font-weight: 400
  line-height: 1.5rem

.segment--skew
  content: ''
  display: block
  height: 5vw
  position: absolute
  right: 0
  top: 0
  width: 100%
  z-index: 1

  background: var(--section-background)
  transform-origin: top right
  transition: background-color var(--transition-subtle)

  &.-down
    transform: skewY(1deg)

  &.-up
    top: -2vw
    transform: skewY(-1deg)

.segment--image
  bottom: 0
  height: 100%
  position: absolute
  right: 0
  top: 0
  width: 40%

  +on-xs
    display: none

  img,
  video
    height: 100%
    object-fit: cover