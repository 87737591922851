@import ../environment

.header
  --header-text-color: var(--white)
  --header-text-active-color: var(--white)
  --header-text-hover-color: var(--white-hover)
  --header-background-color: transparent

  position: fixed
  height: var(--navigation-bar-height)
  width: 100vw

  z-index: 10

  backdrop-filter: blur(4px)
  background-color: var(--header-background-color)
  transition: background-color var(--transition-subtle), color var(--transition-subtle)

  &[data-theme="light"],
  &[data-theme="gray"]
    --header-background-color: var(--brand-blue)

    +when-dark-mode
      --header-background-color: var(--black-20p)

.header--inner
  align-items: center
  display: flex
  justify-content: space-between
  padding-inline: var(--container-padding)

  +supports-max-width

.header--navigation
  column-gap: 20px
  display: flex
  height: var(--navigation-bar-height)
  margin-left: 0

  list-style-type: none

  +on-mobile
    display: none

.header--mobile-navigation
  column-gap: 20px
  display: none
  height: var(--navigation-bar-height)

  +on-mobile
    display: flex

.header--link
  align-items: center
  display: inline-flex
  height: var(--navigation-bar-height)

  &.-logo
    img
      height: 48px
      width: 48px

  cursor: pointer

  [role='button']
    color: var(--header-text-color)

  a
    color: var(--header-text-color)
    font-size: 16px
    font-weight: 400
    text-decoration: none

    &:active,
    &:hover
      color: var(--header-text-hover-color)

  &.-active
    a
      color: var(--header-text-active-color)
      font-weight: 600

.header--tools
  align-items: center
  column-gap: 20px
  display: flex
  height: var(--navigation-bar-height)
