@import ../environment

.list--item
  display: flex
  align-items: center
  column-gap: 16px
  color: var(--ui-secondary-text-color)

  & + &
    margin-top: 16px

.list--item-icon
  display: inline-block
  +dimensions(16px)

  color: var(--ui-primary-interaction-color)

  i
    transform: translateY(1px)
