@import url('https://use.typekit.net/puq3dgh.css')
@import config/reset
@import environment

*
  box-sizing: border-box
  font-family: proxima-nova, sans-serif
  font-weight: 300
  font-style: normal

html
  overflow-x: hidden

body
  overflow-x: hidden

  &[is-dark-mode]
    --ui-primary-background-color: var(--ui-dark-background-gradient)
    --ui-secondary-background-color: #{lighten($lighter-black, 8%)}
    --ui-primary-text-color: var(--white)
    --ui-secondary-text-color: #{darken(white, 5%)}

    --footer-text-color: rgb(153, 162, 172)
    --footer-text-hover-color: var(--white)
    --footer-icon-color: rgb(156, 163, 175)
    --footer-icon-hover-color: var(--white-hover)

    --mobile-navigation-background-color: var(--ui-dark-background-2)
    --mobile-navigation-item-icon-color: #9FA6B2
    --mobile-navigation-item-text-color: #B1B7BE
    --mobile-navigation-item-text-hover-color: var(--white)
    --mobile-navigation-item-hover-color: #161E2E

strong
  font-weight: bold

a
  color: var(--link-color)
  text-decoration: none

  &:active,
  &:hover
    color: var(--link-hover-color)

h1
  margin-bottom: 80px

  font-size: 2rem
  font-weight: bold
  line-height: 2.5rem

h2
  margin-bottom: 20px

  font-size: 1.5rem
  font-weight: bold
  line-height: 2rem

h3
  margin-bottom: 20px

  font-size: 1.25rem
  font-weight: bold
  line-height: 1.5rem

h4
  margin-bottom: 20px

  font-weight: bold

ul
  &.-square-dots
    margin-left: 16px

    list-style-type: square

    li
      line-height: 1.5rem