.mobile-navigation
  display: grid
  grid-template-areas: "home" "." "links" "." "company"
  grid-template-rows: auto 16px auto 1fr auto
  height: calc(100vh - 2 * var(--up-drawer-padding))

.mobile-navigation--header
  margin-left: -8px
  padding: 12px 8px

  color: var(--mobile-navigation-item-text-color)
  font-size: 0.75rem
  font-weight: 400
  letter-spacing: 1px
  text-transform: uppercase

.mobile-navigation--home
  grid-area: home
  padding-inline: calc(#{var(--container-padding)} - #{var(--up-drawer-padding)})
  padding-top: 40px

.mobile-navigation--links
  display: flex
  flex-direction: column
  gap: 48px
  grid-area: links
  padding-inline: calc(#{var(--container-padding)} - #{var(--up-drawer-padding)})

.mobile-navigation--company
  grid-area: company