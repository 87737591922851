.mobile-navigation {
  display: grid;
  grid-template-areas: "home" "." "links" "." "company";
  grid-template-rows: auto 16px auto 1fr auto;
  height: calc(100vh - 2 * var(--up-drawer-padding));
}

.mobile-navigation--header {
  margin-left: -8px;
  padding: 12px 8px;
  color: var(--mobile-navigation-item-text-color);
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.mobile-navigation--home {
  grid-area: home;
  padding-inline: calc(var(--container-padding) - var(--up-drawer-padding));
  padding-top: 40px;
}

.mobile-navigation--links {
  display: flex;
  flex-direction: column;
  gap: 48px;
  grid-area: links;
  padding-inline: calc(var(--container-padding) - var(--up-drawer-padding));
}

.mobile-navigation--company {
  grid-area: company;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsibW9iaWxlX25hdmlnYXRpb24uc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0E7RUFFQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFFRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLm1vYmlsZS1uYXZpZ2F0aW9uXG4gIGRpc3BsYXk6IGdyaWRcbiAgZ3JpZC10ZW1wbGF0ZS1hcmVhczogXCJob21lXCIgXCIuXCIgXCJsaW5rc1wiIFwiLlwiIFwiY29tcGFueVwiXG4gIGdyaWQtdGVtcGxhdGUtcm93czogYXV0byAxNnB4IGF1dG8gMWZyIGF1dG9cbiAgaGVpZ2h0OiBjYWxjKDEwMHZoIC0gMiAqIHZhcigtLXVwLWRyYXdlci1wYWRkaW5nKSlcblxuLm1vYmlsZS1uYXZpZ2F0aW9uLS1oZWFkZXJcbiAgbWFyZ2luLWxlZnQ6IC04cHhcbiAgcGFkZGluZzogMTJweCA4cHhcblxuICBjb2xvcjogdmFyKC0tbW9iaWxlLW5hdmlnYXRpb24taXRlbS10ZXh0LWNvbG9yKVxuICBmb250LXNpemU6IDAuNzVyZW1cbiAgZm9udC13ZWlnaHQ6IDQwMFxuICBsZXR0ZXItc3BhY2luZzogMXB4XG4gIHRleHQtdHJhbnNmb3JtOiB1cHBlcmNhc2VcblxuLm1vYmlsZS1uYXZpZ2F0aW9uLS1ob21lXG4gIGdyaWQtYXJlYTogaG9tZVxuICBwYWRkaW5nLWlubGluZTogY2FsYygje3ZhcigtLWNvbnRhaW5lci1wYWRkaW5nKX0gLSAje3ZhcigtLXVwLWRyYXdlci1wYWRkaW5nKX0pXG4gIHBhZGRpbmctdG9wOiA0MHB4XG5cbi5tb2JpbGUtbmF2aWdhdGlvbi0tbGlua3NcbiAgZGlzcGxheTogZmxleFxuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uXG4gIGdhcDogNDhweFxuICBncmlkLWFyZWE6IGxpbmtzXG4gIHBhZGRpbmctaW5saW5lOiBjYWxjKCN7dmFyKC0tY29udGFpbmVyLXBhZGRpbmcpfSAtICN7dmFyKC0tdXAtZHJhd2VyLXBhZGRpbmcpfSlcblxuLm1vYmlsZS1uYXZpZ2F0aW9uLS1jb21wYW55XG4gIGdyaWQtYXJlYTogY29tcGFueSJdfQ== */