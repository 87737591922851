.splide__progress {
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.splide__progress__bar {
  height: 4px;
  background: var(--ui-primary-interaction-color);
}

[data-section=light] .splide__pagination__page,
[data-section=gray] .splide__pagination__page {
  background-color: var(--ui-secondary-text-color);
}
[data-section=light] .splide__pagination__page.is-active,
[data-section=gray] .splide__pagination__page.is-active {
  background-color: var(--ui-primary-text-color);
}

@media (max-width: 768px) {
  .splide__arrow {
    display: none;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsic3BsaWRlLnNhc3MiLCIuLi9jb25maWcvbWl4aW5zL19icmVha3BvaW50cy5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTtFQUVBOzs7QUFJQTtBQUFBO0VBQ0U7O0FBRUE7QUFBQTtFQUNFOzs7QUNUSjtFRFdGO0lBRUkiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IC4uL2Vudmlyb25tZW50XG5cbi5zcGxpZGVfX3Byb2dyZXNzXG4gIGJvdHRvbTogMFxuICBwb3NpdGlvbjogYWJzb2x1dGVcbiAgbGVmdDogMFxuICByaWdodDogMFxuICB6LWluZGV4OiAxXG5cbi5zcGxpZGVfX3Byb2dyZXNzX19iYXJcbiAgaGVpZ2h0OiA0cHhcblxuICBiYWNrZ3JvdW5kOiB2YXIoLS11aS1wcmltYXJ5LWludGVyYWN0aW9uLWNvbG9yKVxuXG5bZGF0YS1zZWN0aW9uPVwibGlnaHRcIl0sXG5bZGF0YS1zZWN0aW9uPVwiZ3JheVwiXVxuICAuc3BsaWRlX19wYWdpbmF0aW9uX19wYWdlXG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tdWktc2Vjb25kYXJ5LXRleHQtY29sb3IpXG5cbiAgICAmLmlzLWFjdGl2ZVxuICAgICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tdWktcHJpbWFyeS10ZXh0LWNvbG9yKVxuXG4uc3BsaWRlX19hcnJvd1xuICArb24tbW9iaWxlXG4gICAgZGlzcGxheTogbm9uZSIsIkBpbXBvcnQgLi4vZGVmaW5pdGlvbnMvYnJlYWtwb2ludHNcblxuPXN1cHBvcnRzLW1heC13aWR0aFxuICBtYXJnaW4taW5saW5lOiBhdXRvXG4gIG1heC13aWR0aDogMTgwMHB4XG5cbj1vbi1tb2JpbGUtYW5kLXRhYmxldFxuICBAbWVkaWEgKG1heC13aWR0aDogOTkycHgpXG4gICAgQGNvbnRlbnRcblxuPW9uLW1vYmlsZVxuICBAbWVkaWEgKG1heC13aWR0aDogNzY4cHgpXG4gICAgQGNvbnRlbnRcblxuPW9uLXhzXG4gIEBtZWRpYSAobWF4LXdpZHRoOiAkYnJlYWtwb2ludC14cylcbiAgICBAY29udGVudFxuXG49b24tdG9vLXNtYWxsLXNjcmVlbnNcbiAgQG1lZGlhIChtYXgtd2lkdGg6ICRicmVha3BvaW50LXRvby1zbWFsbClcbiAgICBAY29udGVudCJdfQ== */