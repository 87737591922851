.mobile-navigation-link
  align-items: center
  display: grid
  grid-template-areas: "icon text"
  grid-template-columns: auto 1fr
  margin-left: -8px
  padding: 12px 8px

  border-radius: 4px
  text-decoration: none

  &:active,
  &:hover,
  &.-active
    background-color: var(--mobile-navigation-item-hover-color)
    --mobile-navigation-item-icon-color: var(--mobile-navigation-item-text-hover-color)
    --mobile-navigation-item-text-color: var(--mobile-navigation-item-text-hover-color)

  &.-home
    img
      margin-right: 8px

      height: 32px
      width: 32px

.mobile-navigation-link--icon
  grid-area: icon
  height: 16px
  margin-right: 16px
  width: 16px

  color: var(--mobile-navigation-item-icon-color)
  font-size: 16px

.mobile-navigation-link--text
  grid-area: text

  color: var(--mobile-navigation-item-text-color)
  font-size: 0.875rem
  font-weight: 400