@-webkit-keyframes customFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes customFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__animated {
  animation-duration: 1.5s;
}

.animate__customFadeInUp {
  -webkit-animation-name: customFadeInUp;
  animation-name: customFadeInUp;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsiY3VzdG9tX2FuaW1hdGUuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0lBQ0U7SUFDQTtJQUNBOztFQUVGO0lBQ0U7SUFDQTtJQUNBOzs7QUFFSjtFQUNFO0lBQ0U7SUFDQTtJQUNBOztFQUVGO0lBQ0U7SUFDQTtJQUNBOzs7QUFFSjtFQUNFOzs7QUFFRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJALXdlYmtpdC1rZXlmcmFtZXMgY3VzdG9tRmFkZUluVXBcbiAgZnJvbVxuICAgIG9wYWNpdHk6IDBcbiAgICAtd2Via2l0LXRyYW5zZm9ybTogdHJhbnNsYXRlM2QoMCwgMTAwcHgsIDApXG4gICAgdHJhbnNmb3JtOiB0cmFuc2xhdGUzZCgwLCAxMDBweCwgMClcblxuICB0b1xuICAgIG9wYWNpdHk6IDFcbiAgICAtd2Via2l0LXRyYW5zZm9ybTogdHJhbnNsYXRlM2QoMCwgMCwgMClcbiAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZTNkKDAsIDAsIDApXG5cbkBrZXlmcmFtZXMgY3VzdG9tRmFkZUluVXBcbiAgZnJvbVxuICAgIG9wYWNpdHk6IDBcbiAgICAtd2Via2l0LXRyYW5zZm9ybTogdHJhbnNsYXRlM2QoMCwgMTAwcHgsIDApXG4gICAgdHJhbnNmb3JtOiB0cmFuc2xhdGUzZCgwLCAxMDBweCwgMClcblxuICB0b1xuICAgIG9wYWNpdHk6IDFcbiAgICAtd2Via2l0LXRyYW5zZm9ybTogdHJhbnNsYXRlM2QoMCwgMCwgMClcbiAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZTNkKDAsIDAsIDApXG5cbi5hbmltYXRlX19hbmltYXRlZFxuICBhbmltYXRpb24tZHVyYXRpb246IDEuNXNcblxuLmFuaW1hdGVfX2N1c3RvbUZhZGVJblVwXG4gIC13ZWJraXQtYW5pbWF0aW9uLW5hbWU6IGN1c3RvbUZhZGVJblVwXG4gIGFuaW1hdGlvbi1uYW1lOiBjdXN0b21GYWRlSW5VcCJdfQ== */