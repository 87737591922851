.logo {
  width: 128px;
  height: 128px;
  user-select: none;
}

.logo--link {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 20px;
  text-decoration: none;
}

.logo--copyright {
  font-size: 16px;
  color: rgb(203, 213, 225);
  margin-top: 80px;
  text-align: center;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsibG9nby5zYXNzIiwiLi4vY29uZmlnL21peGlucy9fc2l6ZXMuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQ0RFLE9ERVk7RUNEWixRRENZO0VBQ1o7OztBQUVGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgLi4vZW52aXJvbm1lbnRcblxuLmxvZ29cbiAgK2RpbWVuc2lvbnMoMTI4cHgpXG4gIHVzZXItc2VsZWN0OiBub25lXG5cbi5sb2dvLS1saW5rXG4gIGRpc3BsYXk6IGZsZXhcbiAgZmxleC13cmFwOiB3cmFwXG4gIGNvbHVtbi1nYXA6IDQwcHhcbiAgcm93LWdhcDogMjBweFxuICB0ZXh0LWRlY29yYXRpb246IG5vbmVcblxuLmxvZ28tLWNvcHlyaWdodFxuICBmb250LXNpemU6IDE2cHhcbiAgY29sb3I6IHJnYigyMDMgMjEzIDIyNSlcbiAgbWFyZ2luLXRvcDogODBweFxuICB0ZXh0LWFsaWduOiBjZW50ZXIiLCI9ZGltZW5zaW9ucygkd2lkdGgsICRoZWlnaHQ6ICR3aWR0aClcbiAgd2lkdGg6ICR3aWR0aFxuICBoZWlnaHQ6ICRoZWlnaHQiXX0= */