@import ../environment

.header-theme-switcher--button
  color: var(--header-text-color)

  &:active,
  &:hover
    color: var(--header-text-hover-color)

  &.for-light
    display: none

  &.for-dark
    display: initial

  +when-dark-mode
    &.for-light
      display: initial

    &.for-dark
      display: none