=when-locale-switcher-initialized
  .locale-switcher.-initialized &
    @content

.locale-switcher--button
  display: none

  cursor: pointer

  +when-locale-switcher-initialized
    display: block

.locale-switcher--popup
  +when-locale-switcher-initialized
    display: none

.locale-switcher--toggles
  display: flex
  gap: 8px