.tai--image img {
  box-shadow: 0 35px 80px -12px rgba(0, 0, 0, 0.9);
}
@media (max-width: 768px) {
  .tai--image img {
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.9);
  }
}
@media (max-width: 992px) {
  .tai--image.-shrink-on-mobile {
    margin-inline: auto;
    width: 60%;
  }
}
@media (max-width: 430px) {
  .tai--image.-shrink-on-mobile {
    width: 80%;
  }
}
.tai--image.-without-shadow img {
  box-shadow: none;
}
@media (max-width: 300px) {
  .tai--image {
    display: none;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsidGFpLnNhc3MiLCIuLi9jb25maWcvbWl4aW5zL19icmVha3BvaW50cy5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUdFO0VBQ0U7O0FDT0Y7RURSQTtJQUlJOzs7QUNBSjtFREVBO0lBRUk7SUFDQTs7O0FDR0o7RUROQTtJQU1JOzs7QUFHRjtFQUNFOztBQ0FKO0VEakJGO0lBb0JJIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAuLi9lbnZpcm9ubWVudFxuXG4udGFpLS1pbWFnZVxuICBpbWdcbiAgICBib3gtc2hhZG93OiAwIDM1cHggODBweCAtMTJweCByZ2IoMCAwIDAgLyAwLjkpXG5cbiAgICArb24tbW9iaWxlXG4gICAgICBib3gtc2hhZG93OiAwIDEwcHggMzBweCAtMTJweCByZ2IoMCAwIDAgLyAwLjkpXG5cbiAgJi4tc2hyaW5rLW9uLW1vYmlsZVxuICAgICtvbi1tb2JpbGUtYW5kLXRhYmxldFxuICAgICAgbWFyZ2luLWlubGluZTogYXV0b1xuICAgICAgd2lkdGg6IDYwJVxuXG4gICAgK29uLXhzXG4gICAgICB3aWR0aDogODAlXG5cbiAgJi4td2l0aG91dC1zaGFkb3dcbiAgICBpbWdcbiAgICAgIGJveC1zaGFkb3c6IG5vbmVcblxuICArb24tdG9vLXNtYWxsLXNjcmVlbnNcbiAgICBkaXNwbGF5OiBub25lIiwiQGltcG9ydCAuLi9kZWZpbml0aW9ucy9icmVha3BvaW50c1xuXG49c3VwcG9ydHMtbWF4LXdpZHRoXG4gIG1hcmdpbi1pbmxpbmU6IGF1dG9cbiAgbWF4LXdpZHRoOiAxODAwcHhcblxuPW9uLW1vYmlsZS1hbmQtdGFibGV0XG4gIEBtZWRpYSAobWF4LXdpZHRoOiA5OTJweClcbiAgICBAY29udGVudFxuXG49b24tbW9iaWxlXG4gIEBtZWRpYSAobWF4LXdpZHRoOiA3NjhweClcbiAgICBAY29udGVudFxuXG49b24teHNcbiAgQG1lZGlhIChtYXgtd2lkdGg6ICRicmVha3BvaW50LXhzKVxuICAgIEBjb250ZW50XG5cbj1vbi10b28tc21hbGwtc2NyZWVuc1xuICBAbWVkaWEgKG1heC13aWR0aDogJGJyZWFrcG9pbnQtdG9vLXNtYWxsKVxuICAgIEBjb250ZW50Il19 */