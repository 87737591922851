@import ../environment

.button
  display: inline-block
  padding: 12px 16px
  background-color: var(--ui-primary-interaction-color)
  border-radius: 4px
  border: 1px solid var(--ui-primary-interaction-color)
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25)
  color: var(--white)
  font-size: 1rem
  text-decoration: none
  font-weight: 300
  transition: var(--transition-subtle)

  cursor: pointer

  +on-mobile
    padding: 8px 16px

  &.-transparent
    background-color: transparent
    color: #F9FAFB
    border: 1px solid #F9FAFB

    &:active,
    &:hover
      background-color: rgba(white, 0.4)

  &.-small
    padding: 8px 16px

  &:active,
  &:hover
    background-color: var(--ui-primary-interaction-hover-color)
    color: var(--white)