.dropdown
  display: flex
  align-items: center
  cursor: pointer

  &::after
    content: '▼'
    margin-left: 8px
    transform: translateY(2px)

    color: var(--white)
    font-size: 12px

  &:active,
  &:hover
    &::after
      color: var(--white-hover)