.price-box-overview
  --spacing: 20px

  display: grid
  grid-template-areas: "name" "." "price" "." "description"
  grid-template-rows: auto var(--spacing) auto var(--spacing) 1fr

.price-box-overview--name
  justify-self: center
  grid-area: name

  text-transform: uppercase

.price-box-overview--price
  grid-area: price
  justify-self: center

  font-size: 48px
  font-weight: bold

.price-box-overview--unit
  font-size: 36px
  font-weight: bolder

.price-box-overview--description
  grid-area: description

  color: var(--ui-secondary-text-color)
  font-size: 18px
  font-weight: 400
  line-height: 24px
  text-align: center