@-webkit-keyframes customFadeInUp
  from
    opacity: 0
    -webkit-transform: translate3d(0, 100px, 0)
    transform: translate3d(0, 100px, 0)

  to
    opacity: 1
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

@keyframes customFadeInUp
  from
    opacity: 0
    -webkit-transform: translate3d(0, 100px, 0)
    transform: translate3d(0, 100px, 0)

  to
    opacity: 1
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

.animate__animated
  animation-duration: 1.5s

.animate__customFadeInUp
  -webkit-animation-name: customFadeInUp
  animation-name: customFadeInUp