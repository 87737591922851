.background-medium {
  position: relative;
  max-height: calc(100vh + 20px);
}
.background-medium.-narrow {
  height: 60vh;
}
.background-medium video,
.background-medium img {
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.background-medium--overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.4);
}
.background-medium--overlay.-darker {
  background-color: rgba(0, 0, 0, 0.6);
}
.background-medium--content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  color: white;
}
.background-medium--gradient {
  position: absolute;
  width: 100%;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.4);
}
.background-medium--gradient.-top {
  top: 0;
}
.background-medium--gradient.-bottom {
  bottom: 0;
}
.background-medium--separator {
  height: 4px;
  background-color: black;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsiYmFja2dyb3VuZF9tZWRpdW0uc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7O0FBRUE7RUFDRTs7QUFFRjtBQUFBO0VBRUU7RUFDQTtFQUNBO0VBQ0E7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBRUo7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBRUY7RUFDRTs7QUFFSjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuYmFja2dyb3VuZC1tZWRpdW1cbiAgcG9zaXRpb246IHJlbGF0aXZlXG4gIG1heC1oZWlnaHQ6IGNhbGMoMTAwdmggKyAyMHB4KVxuXG4gICYuLW5hcnJvd1xuICAgIGhlaWdodDogY2FsYyg2MHZoKVxuXG4gIHZpZGVvLFxuICBpbWdcbiAgICB6LWluZGV4OiAxXG4gICAgd2lkdGg6IDEwMCVcbiAgICBoZWlnaHQ6IDEwMCVcbiAgICBvYmplY3QtZml0OiBjb3ZlclxuXG4gICYtLW92ZXJsYXlcbiAgICBwb3NpdGlvbjogYWJzb2x1dGVcbiAgICB0b3A6IDBcbiAgICBsZWZ0OiAwXG4gICAgei1pbmRleDogMlxuICAgIHdpZHRoOiAxMDAlXG4gICAgaGVpZ2h0OiAxMDAlXG4gICAgYmFja2Ryb3AtZmlsdGVyOiBibHVyKDZweClcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiByZ2JhKCMwMDAsIDAuNClcblxuICAgICYuLWRhcmtlclxuICAgICAgYmFja2dyb3VuZC1jb2xvcjogcmdiYSgjMDAwLCAwLjYpXG5cbiAgJi0tY29udGVudFxuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZVxuICAgIHRvcDogMFxuICAgIGxlZnQ6IDBcbiAgICB6LWluZGV4OiAzXG4gICAgd2lkdGg6IDEwMCVcbiAgICBoZWlnaHQ6IDEwMCVcbiAgICBjb2xvcjogd2hpdGVcblxuICAmLS1ncmFkaWVudFxuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZVxuICAgIHdpZHRoOiAxMDAlXG4gICAgaGVpZ2h0OiAyMHB4XG4gICAgYmFja2dyb3VuZC1jb2xvcjogcmdiYSgyNTUsIDI1NSwgMjU1LCAwLjQpXG5cbiAgICAmLi10b3BcbiAgICAgIHRvcDogMFxuXG4gICAgJi4tYm90dG9tXG4gICAgICBib3R0b206IDBcblxuICAmLS1zZXBhcmF0b3JcbiAgICBoZWlnaHQ6IDRweFxuICAgIGJhY2tncm91bmQtY29sb3I6IGJsYWNrIl19 */