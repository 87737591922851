@import ../mixins/breakpoints

\:root
  --container-padding: 12%
  --navigation-bar-height: 60px

  +on-mobile
    --container-padding: 40px

  +on-xs
    --container-padding: 20px