.locale-switcher-toggle {
  align-items: center;
  display: flex;
  gap: 8px;
  text-decoration: none;
}

.locale-switcher-toggle--title {
  color: var(--white);
  font-size: 0.875rem;
}
.is-active .locale-switcher-toggle--title {
  font-weight: bold;
}

.locale-switcher-toggle--separator {
  height: 20px;
  margin-inline: 4px;
  width: 1px;
  background-color: var(--white);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsibG9jYWxlX3N3aXRjaGVyX3RvZ2dsZS5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBRUE7OztBQUVGO0VBQ0U7RUFDQTs7QUFFQTtFQUNFOzs7QUFFSjtFQUNFO0VBQ0E7RUFDQTtFQUVBIiwic291cmNlc0NvbnRlbnQiOlsiLmxvY2FsZS1zd2l0Y2hlci10b2dnbGVcbiAgYWxpZ24taXRlbXM6IGNlbnRlclxuICBkaXNwbGF5OiBmbGV4XG4gIGdhcDogOHB4XG5cbiAgdGV4dC1kZWNvcmF0aW9uOiBub25lXG5cbi5sb2NhbGUtc3dpdGNoZXItdG9nZ2xlLS10aXRsZVxuICBjb2xvcjogdmFyKC0td2hpdGUpXG4gIGZvbnQtc2l6ZTogMC44NzVyZW1cblxuICAuaXMtYWN0aXZlICZcbiAgICBmb250LXdlaWdodDogYm9sZFxuXG4ubG9jYWxlLXN3aXRjaGVyLXRvZ2dsZS0tc2VwYXJhdG9yXG4gIGhlaWdodDogMjBweFxuICBtYXJnaW4taW5saW5lOiA0cHhcbiAgd2lkdGg6IDFweFxuXG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXdoaXRlKSJdfQ== */