.segment-title {
  font-size: 2rem;
  font-weight: bold;
  hyphens: auto;
  line-height: 2.5rem;
}
.segment-title.-small {
  font-size: 48px;
}

.segment-title-decorators {
  margin-bottom: var(--segment-title-margin-bottom);
  margin-top: calc(-1 * var(--segment-title-margin-bottom));
}

.segment-title-decorator {
  display: inline-block;
  height: 4px;
  width: 50px;
}
.segment-title-decorator + .segment-title-decorator {
  margin-left: -2px;
}
.segment-title-decorator:first-child {
  background-color: var(--ui-primary-interaction-color);
}
.segment-title-decorator:last-child {
  background-color: var(--ui-primary-interaction-hover-color);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kb21pbmljLWJlZ2VyLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsic2VnbWVudF90aXRsZS5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTs7O0FBRUo7RUFDRTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOztBQUVGO0VBQ0U7O0FBQ0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgLi4vZW52aXJvbm1lbnRcblxuLnNlZ21lbnQtdGl0bGVcbiAgZm9udC1zaXplOiAycmVtXG4gIGZvbnQtd2VpZ2h0OiBib2xkXG4gIGh5cGhlbnM6IGF1dG9cbiAgbGluZS1oZWlnaHQ6IDIuNXJlbVxuXG4gICYuLXNtYWxsXG4gICAgZm9udC1zaXplOiA0OHB4XG5cbi5zZWdtZW50LXRpdGxlLWRlY29yYXRvcnNcbiAgbWFyZ2luLWJvdHRvbTogdmFyKC0tc2VnbWVudC10aXRsZS1tYXJnaW4tYm90dG9tKVxuICBtYXJnaW4tdG9wOiBjYWxjKC0xICogdmFyKC0tc2VnbWVudC10aXRsZS1tYXJnaW4tYm90dG9tKSlcblxuLnNlZ21lbnQtdGl0bGUtZGVjb3JhdG9yXG4gIGRpc3BsYXk6IGlubGluZS1ibG9ja1xuICBoZWlnaHQ6IDRweFxuICB3aWR0aDogNTBweFxuXG4gICYgKyAmXG4gICAgbWFyZ2luLWxlZnQ6IC0ycHhcblxuICAmOmZpcnN0LWNoaWxkXG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tdWktcHJpbWFyeS1pbnRlcmFjdGlvbi1jb2xvcilcbiAgJjpsYXN0LWNoaWxkXG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tdWktcHJpbWFyeS1pbnRlcmFjdGlvbi1ob3Zlci1jb2xvcikiXX0= */